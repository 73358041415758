<template>
  <div>
    <div class="row">
      <div class="col-lg-4 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 mt-2">
              <filter-regional v-model:value="regionals" />
          </div>
          <div class="card-body mt-2 ml-2">
              <filter-area v-model:value="areas" v-model:province="province" />
          </div>
          <div class="card-body mt-2 ml-2">
                <filter-distributor v-model:value="distributors"></filter-distributor>
          </div>
          <div class="card-body mt-2 ml-2">
                <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Tahun">
            <a-select-option value="2010">2010</a-select-option>
            <a-select-option value="2011">2011</a-select-option>
            <a-select-option value="2012">2012</a-select-option>
            <a-select-option value="2013">2013</a-select-option>
            <a-select-option value="2014">2014</a-select-option>
            <a-select-option value="2015">2015</a-select-option>
            <a-select-option value="2016">2016</a-select-option>
            <a-select-option value="2017">2017</a-select-option>
            <a-select-option value="2018">2018</a-select-option>
            <a-select-option value="2019">2019</a-select-option>
            <a-select-option value="2020">2020</a-select-option>
             </a-select>
          </div>
          <div class="card-body ml-2 mt-2">
          <a-input-search
              v-model:value="value"
              placeholder="Cari ..."
              style="width: 300px"
              @search="onSearch"
            />
            <a-button class="ml-2" type="primary" title="cari" @click="search">
          <i class="fa fa-search" aria-hidden="true"></i>
        </a-button>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="card-placeholder">
          <div class="card-body ml-2 mt-2">
                <filter-province v-model:value="province" v-model:regional="regionals" />
          </div>
          <div class="card-body ml-2 mt-2">
               <filter-kabupaten-kota v-model:value="kabupaten" v-model:area="areas" />
          </div>
          <div class="card-body ml-2 mt-2">
                <a-select label-in-value v-model:value="value" style="width: 300px" @change="handleChange" placeholder="Pilih Toko">
            <a-select-option value=""></a-select-option>
             </a-select>
          </div>
          <div class="card-body mt-2 ml-2">
        <router-link to="/laporan/unggah-program-up-front"><a-button type="primary" class="ml-2" style="float: right" title="Unggah Up Front">
          <i class="fa fa-upload"></i>
          </a-button></router-link>
        <a-button
        style="float: right;"
        class="ml-2"
          type="primary"
          title="Download Xls"
          @click="fetchXlsx"
        >
          <i class="fa fa-file-excel-o" aria-hidden="true"></i>
        </a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body ">
        <div class="table-responsive">
      <a-table :columns="columns"
              :data-source="data"
              :row-selection="rowSelection"
              size="small"
              row-key="id"
              :pagination="{
                showSizeChanger: true,
                pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                total: meta.totalCount,
                pageSize: meta.perPage,
                current: meta.currentPage,
              }"
              :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
              bordered
              @change="handleTableChange"
              :loading="isFetching"
            >
        <template
          #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
        >
          <div class="custom-filter-dropdown">
            <a-input
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="() => handleSearch(selectedKeys, confirm)"
            />
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="() => handleSearch(selectedKeys, confirm)"
              >Search</a-button
            >
            <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
              >Reset</a-button
            >
          </div> </template
        ><template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #filterIcon="{ text: filtered }">
          <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
        </template>
        <template #customer="{ text }">
          <span v-if="searchText">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
          </template>
        </template>
        <template #progress="{ text: bar }">
          <div class="progress">
            <div
              :class="['progress-bar', bar.color]"
              :style="{ width: bar.value + '%' }"
              role="progressbar"
            ></div>
          </div>
        </template>
        <template #value="{ text }">
          <span class="font-weight-bold">{{ text }}</span>
        </template>
        <template #id="{ text }">
          <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
        </template>
        <template #total="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #tax="{ text }">
          <span>${{ text }}</span>
        </template>
        <template #shipping="{ text }">
          <span>${{ text }}</span>
        </template>
      </a-table>
    </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, reactive, provide, toRefs } from 'vue'
import adata from './data.json'
import apiClient from '@/services/axios'
// import MModal from './modal'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterProvince from '@/components/filter/FilterProvince'
import FilterArea from '@/components/filter/FilterArea'
import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
// import FilterDistributor from '@/components/filter/FilterDistributor'
const acolumns = [
   {
    title: 'NO',
    dataIndex: 'no',
    slots: { customRender: 'no' },
    sorter: (a, b) => a.no - b.no,
  },
   {
    title: 'ID BK',
    dataIndex: 'id_bk',
    slots: { customRender: 'idbk' },
    sorter: (a, b) => a.id - b.id,
  },
  {
    title: 'Nama Toko',
    dataIndex: 'nama_toko',
    slots: { customRender: 'nama_toko' },
  },
  {
    title: 'Nama Customer',
    dataIndex: 'nama_customer',
    slots: { customRender: 'nama_customer' },
  },
  {
    title: 'Tahun',
    dataIndex: 'tahun',
    slots: { customRender: 'tahun' },
  },
  {
    title: 'Januari',
    dataIndex: 'januari',
    slots: { customRender: 'januari' },
  },
  {
    title: 'Februari',
    dataIndex: 'februari',
    slots: { customRender: 'februari' },
  },
{
    title: 'Maret',
    dataIndex: 'maret',
    slots: { customRender: 'maret' },
  },
  {
    title: 'April',
    dataIndex: 'april',
    slots: { customRender: 'april' },
  },
  {
    title: 'Mei',
    dataIndex: 'mei',
    slots: { customRender: 'mei' },
  },
  {
    title: 'Juni',
    dataIndex: 'juni',
    slots: { customRender: 'juni' },
  },
  {
    title: 'Juli',
    dataIndex: 'juli',
    slots: { customRender: 'juli' },
  },
  {
    title: 'Agustus',
    dataIndex: 'agustus',
    slots: { customRender: 'agustus' },
  },
  {
    title: 'September',
    dataIndex: 'september',
    slots: { customRender: 'september' },
  },
  {
    title: 'Oktober',
    dataIndex: 'oktober',
    slots: { customRender: 'oktober' },
  },
  {
    title: 'November',
    dataIndex: 'november',
    slots: { customRender: 'november' },
  },
  {
    title: 'Desember',
    dataIndex: 'desember',
    slots: { customRender: 'desember' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
// function renderVNode(_, { attrs: { vnode } }) {
// return vnode
// }
export default {
  components: {
    // MModal,
    FilterRegional,
    FilterProvince,
    FilterArea,
    FilterKabupatenKota,
    // FilterDistributor,
  },
  setup() {
    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const searchText = ref('')
    const searchInput = ref(null)
    const columns = ref([])
    const data = ref([])
    const current1 = ref(1)
    data.value = adata.slice(1, 5)
    columns.value = acolumns

    const handleSearch = (selectedKeys, confirm) => {
      confirm()
      searchText.value = selectedKeys[0]
    }

    const handleReset = clearFilters => {
      clearFilters()
      searchText.value = ''
    }

    return {
      searchText,
      searchInput,
      data,
      columns,
      handleReset,
      handleSearch,
      current1,
       perPage,
      totalCount,
      pageCount,
      page,
      meta,
    }
  },
}
</script>
<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>

